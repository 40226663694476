
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import ProductionStage from "@/components/Manufacturing/ProductionStage.vue";

export default defineComponent({
  name: "SalesOrders",
  components: {
    ProductionStage,
    Card,
  },
  computed: {
    ...mapState(["shipControl"]),
    ...mapGetters({}),
  },
  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      filename: "SOH",
      productionStage: ["O"],
      pageTitle: "Sales Orders",
      dynamicColumns: [
        { field: "CUST_CODE", header: "Cust Acct #", input: true },
        { field: "PO_NUMBER", header: "Cust PO", input: true },
        { field: "CUST_PART_NO_VIEW", header: "Cust Part No", input: true },
        { field: "FORMULA_PART_VIEW", header: "Formula Part#", input: true },
        { field: "COSWAY_PART", header: "Cosway Part Nbr", input: true },
        { field: "UNIT_SIZE", header: "Size", input: true },
        { field: "ORIGINAL_ID_VIEW", header: "Sales Order", input: true },
        {
          field: "SOH_REQ_DATE",
          header: "Date Req",
          input: false,
          calendar: true,
        },
        { field: "QTY_SCHED", header: "Qty Sched", input: true },
        {
          field: "PROD_SCHED",
          header: "Prod Schedule",
          input: false,
          calendar: true,
        },
        {
          field: "SOH_ORD_QTY",
          header: "PO Qty",
          input: true,
          calendar: false,
        },
        { field: "QTY_PROD", header: "Qty Prod", input: true, calendar: false },
        { field: "QTY_SHIP", header: "Qty Ship", input: true, calendar: false },
        { field: "PROD_DESC_VIEW", header: "Description", input: true },
        { field: "SOH_STATUS", header: "Status", input: true },
        { field: "SOD_DLV_REF", header: "Delv Ref", input: true },
      ],
    };
  },

  methods: {
    ...mapActions({}),
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
